<template>
  <v-dialog v-model="show" max-width="500" max-height="800" persistent>
    <v-card class="mx-auto" max-width="500">
      <v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDrawer"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-actions>

        <v-window v-model="step">
          <v-window-item :value="0">
            <v-container>
              <v-card flat>
                <v-card-title class="headline">
                  Är du säker att du vill skicka produkt utan pristilägg?
                </v-card-title>

                <v-card-actions>
                  <v-btn color="error" text @click="productsDrawer = false">
                    Avbryt
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="nextStep(1)">
                    Ja nästa
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-container>
          </v-window-item>
          <v-window-item :value="1">
            <v-container>
              <div style="overflow: scroll; height: 55vh">
                <div v-for="pack in packages" :key="pack.id">
                  <v-card @click="setCurrentPackage(pack)">
                    <div class="d-flex flex-no-wrap justify-space-between">
                      <div>
                        <v-card-title
                          class="text-h5"
                          v-text="pack.name"
                        ></v-card-title>

                        <v-card-subtitle>{{ getText(pack) }}</v-card-subtitle>
                        <v-card-text class="text--primary">
                          <v-chip
                            class="ma-2"
                            color="primary"
                            text-color="white"
                          >
                            <v-avatar left class="green darken-4">
                              {{ pack.products.length }}
                            </v-avatar>
                            {{
                              pack.products.length > 1 ? "produkter" : "produkt"
                            }}
                          </v-chip>
                          <div>{{ pack.products.length }}st produkter</div>
                        </v-card-text>
                      </div>

                      <v-avatar class="ma-3" size="125" tile>
                        <v-img :src="pack.url" aspect-ratio="0.9"></v-img>
                      </v-avatar>
                    </div>
                  </v-card>
                </div>
              </div>
              <!-- <v-card
                v-for="pack in packages"
                :key="pack.id"
                class="mb-3"
                @click="setCurrentPackage(pack)"
                color="transparent"
              >
                <div class="d-flex flex-no-wrap justify-space-between">
                  <div>
                    <v-card-title class="headline">{{
                      pack.name
                    }}</v-card-title>

                    <v-card-subtitle
                      >Giltigt till:
                      {{
                        formatDate(pack.valid_to, "YYYY-MM-DD")
                      }}</v-card-subtitle
                    >
                  </div>

                  <v-avatar class="ma-3" size="125" tile>
                    <v-img :src="pack.url"></v-img>
                  </v-avatar>
                </div>
              </v-card> -->
            </v-container>
          </v-window-item>
          <v-window-item :value="2">
            <v-card-text>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-xs-center"
                    >Paket: {{ currentPackage.name }}</v-list-item-title
                  >
                  <v-divider class="mt-3"></v-divider>
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th class="text-xs-left">Namn</th>
                        <th class="text-xs-left">Pris</th>
                        <th class="text-xs-left">Artikelnummer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in currentProducts" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td>{{ item.price }}</td>
                        <td>{{ item.article_nr }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-window-item>
          <v-window-item :value="3">
            <v-card-text>
              <v-list-item-title class="text-xs-center"
                >Lägg till produkter</v-list-item-title
              >
              <v-divider class="mt-3"></v-divider>
              <v-container grid-list-xs fluid>
                <v-form ref="newProduct">
                  <v-layout wrap row>
                    <v-flex xs12>
                      <v-text-field
                        v-model="product.name"
                        label="Produktnamn"
                        required
                        filled
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        v-model="product.price"
                        label="Pris"
                        required
                        filled
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6>
                      <v-text-field
                        v-model="product.article_nr"
                        label="Artikelnummer"
                        required
                        filled
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                      <v-btn color="primary" rounded block @click="addProduct"
                        >Lägg till</v-btn
                      >
                    </v-flex>
                    <v-flex>
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th class="text-xs-left">Namn</th>
                            <th class="text-xs-left">Pris</th>
                            <th class="text-xs-left">Artikelnummer</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in currentProducts" :key="item.name">
                            <td>{{ item.name }}</td>
                            <td>{{ item.price }}</td>
                            <td>{{ item.article_nr }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-container>
            </v-card-text>
          </v-window-item>
        </v-window>
        <v-divider></v-divider>
        <v-card-actions v-if="step == 1">
          <v-btn color="primary" block @click="nextStep(3)">Utan paket</v-btn>
        </v-card-actions>
        <v-card-actions v-if="step == 2">
          <v-toolbar flat>
            <v-toolbar-items>
              <v-btn text @click="step--"
                ><v-icon left>mdi-chevron-left</v-icon> Tillbaka</v-btn
              >
            </v-toolbar-items>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn color="primary" @click="saveProducts">Välj</v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-actions>
        <v-card-actions v-if="step == 3">
          <v-toolbar flat>
            <v-toolbar-items>
              <v-btn text @click="step = 1"
                ><v-icon left>mdi-chevron-left</v-icon> Tillbaka</v-btn
              >
            </v-toolbar-items>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn color="primary" @click="saveProducts">Välj</v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import api from "../../clasess/api";

export default {
  props: ["show", "sale"],

  computed: {
    ...mapGetters("order", ["saleById"])
  },

  async mounted() {
    let { packages } = await api.get("packages", {});
    this.packages = packages;
  },

  watch: {
    // show() {
    //   if (this.show) {
    //     if (this.saleById(this.sale).products.length) {
    //       this.step = 3;
    //       this.currentProducts = this.saleById(this.sale).products;
    //     }
    //   }
    // }
  },

  data() {
    return {
      packages: [],
      step: 1,
      currentSale: {},
      currentPackage: "",
      currentProducts: [],
      product: {
        name: "",
        price: "",
        article_nr: ""
      }
    };
  },

  methods: {
    getText(item) {
      if (!item.indefinitely)
        return `Gilltig till ${this.formatDate(item.validTo)}`;
      else return "Gilltig tillsvidare";
    },

    closeDrawer() {
      this.$parent.$parent.productsDrawer = false;
      this.currentProducts = [];
      this.step = 1;
      this.product = {
        name: "",
        price: "",
        article_nr: ""
      };
    },

    addProduct() {
      if (this.$refs.newProduct.validate()) {
        this.currentProducts.push(Object.assign({}, this.product));
        this.product = Object.assign(
          {},
          {
            name: "",
            price: "",
            article_nr: ""
          }
        );
      }
    },

    saveProducts() {
      this.$parent.$parent.productsDrawer = false;
      store.dispatch("order/updateProductsOnSale", {
        id: this.sale,
        products: this.currentProducts
      });
    },

    nextStep(nr) {
      this.step = nr;
      if (nr === 3) this.currentProducts = [];
    },

    setCurrentPackage(item) {
      console.log(item);
      this.currentProducts = item.products;
      this.$parent.$parent.productsDrawer = false;
      store.dispatch("order/updateProductsOnSale", {
        id: this.sale,
        products: item.products
      });
      this.step = 1;
      // this.$parent.productsDrawer = false;
    }
  }
};
</script>
